import { useCallback, useEffect, useState } from 'react';

import {
  Button,
  ETypeFolder,
  type IBaseModalProps,
  useToast,
} from '@gbs-monorepo-packages/common';

import {
  type IDocumentDTO,
  getPrivateDocument,
  getPublicDocument,
} from '../../../../services/documentsFolder';
import {
  BaseModalCustom,
  ButtonsContainer,
  Container,
  Content,
  Description,
  IFrame,
  Image,
  ImageContainer,
  Loading,
  Title,
} from './styles';

export interface IFormModalProps extends Partial<IBaseModalProps> {
  dataCy?: string;
  errorMessage?: string;
  document: IDocumentDTO;
  typeFolder: number;
  onDecline: () => void;
}

const objPreviewMineTypes = {
  pdf: 'application/pdf',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  txt: 'text/plain',
  webp: 'image/webp',
};

const objImageMineTypes = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  webp: 'image/webp',
};

const objNoPreviewMineTypes = {
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  svg: 'image/svg+xml',
  mp3: 'audio/mpeg',
  mp4: 'video/mp4',
  mov: 'video/quicktime',
  wmv: 'video/x-ms-wmv',
  mkv: 'video/x-matroska',
  webm: 'video/webm',
  csv: 'text/csv',
  htm: 'text/html',
  html: 'text/html',
  bmp: 'image/bmp',
  xlsb: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  xlt: 'application/vnd.ms-excel',
  xltm: 'application/vnd.ms-excel.template.macroEnabled.12',
  xltx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  xlw: 'application/vnd.ms-excel',
  xml: 'text/xml',
};

const mineTypesArray = Object.entries(objPreviewMineTypes);

const mineTypesNoPreviewArray = Object.entries(objNoPreviewMineTypes);

export const ModalPreview = ({
  dataCy = 'form-modal',
  document,
  typeFolder = ETypeFolder.PUBLIC,
  onDecline,
  ...props
}: IFormModalProps): JSX.Element => {
  const [data64, setData64] = useState<string>('');
  const [mineType, setMineType] = useState<string>('');
  const [invalidMineType, setInvalidMineType] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [blobUrl, setBlobUrl] = useState('');

  const { addToast } = useToast();

  const handleDecline = useCallback(() => {
    onDecline?.();
  }, [onDecline]);

  useEffect(() => {
    const tmpMineTypeNoPreview = mineTypesNoPreviewArray.find(
      ([key]) => key === document?.extension
    );
    if (tmpMineTypeNoPreview) setInvalidMineType(tmpMineTypeNoPreview[1]);
  }, [document]);

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        setLoading(true);

        const isPublicImage =
          typeFolder !== ETypeFolder.PRIVATE &&
          ['jpg', 'jpeg'].includes(document?.extension || '');

        const response = isPublicImage
          ? await getPublicDocument({ documentId: String(document?.id) })
          : await getPrivateDocument({ documentId: String(document?.id) });

        const { data } = response;
        setData64(data);

        const extension = document?.extension;
        const mimeType = mineTypesArray.find(([key]) => key === extension)?.[1];

        if (mimeType) {
          setMineType(mimeType);

          const blob = await (
            await fetch(`data:${mimeType};base64,${data}`)
          ).blob();
          setBlobUrl(URL.createObjectURL(blob));
        }
      } catch (error) {
        addToast({
          title: 'Error on load document',
          description:
            'An error occurred. Please try again or contact Edge support.',
          styleType: 'error',
          dataCy: 'preview-document-error-toast',
          duration: 3000,
        });
      } finally {
        setLoading(false);
      }
    };

    const shouldFetch =
      props.open &&
      document?.id &&
      (typeFolder === ETypeFolder.PRIVATE ||
        ['jpg', 'jpeg'].includes(document?.extension || ''));

    if (shouldFetch) {
      void fetchDocument();
    }
  }, [document, props.open]);

  const handleDownload = () => {
    if (typeFolder === ETypeFolder.PRIVATE || mineType === 'image/jpeg') {
      const link = window.document.createElement('a');

      if (mineType) {
        link.href = `data:${mineType};base64,${data64}`;
      } else {
        link.href = `data:${invalidMineType};base64,${data64}`;
      }

      link.target = '_blank';

      let fileName = document.name || 'download';

      if (
        mineType === 'image/jpeg' &&
        !fileName.endsWith('.jpg') &&
        !fileName.endsWith('.jpeg')
      ) {
        fileName += '.jpg';
      }

      link.download = fileName;

      link.click();
    } else {
      window.open(`${document.path}`, '_blank')?.focus();
    }
  };

  const documentSection = (documentPath: string) => {
    return (
      <>
        {(objImageMineTypes as Record<string, string>)[document.extension] ? (
          <ImageContainer>
            <Image src={documentPath} alt="preview" />
          </ImageContainer>
        ) : (
          <IFrame src={documentPath}></IFrame>
        )}
      </>
    );
  };

  return (
    <BaseModalCustom dataCy={dataCy} onOpenChange={handleDecline} {...props}>
      <Content>
        {loading ? (
          <Container data-cy="loading-preview">
            <Loading />
          </Container>
        ) : invalidMineType ? (
          <Container>
            <Title>Invalid file type</Title>
            <Description>
              The file type is not supported. Please download the file to view
              it.
            </Description>
          </Container>
        ) : !loading && typeFolder === ETypeFolder.PRIVATE ? (
          <>{documentSection(blobUrl)}</>
        ) : (
          <>{documentSection(document.path)}</>
        )}
        {!loading && (
          <ButtonsContainer>
            <Button type="submit" dataCy="button-add" onClick={handleDownload}>
              Download
            </Button>
          </ButtonsContainer>
        )}
      </Content>
    </BaseModalCustom>
  );
};
