import { isAxiosError } from 'axios';
import { format } from 'date-fns';
import { produce } from 'immer';
import {
  type ChangeEvent,
  type MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { type IUserDTO, useAuth } from '@gbs-monorepo-packages/auth';
import {
  BaseDropdown,
  BaseDropdownItem,
  BreadcrumbsComponent,
  Button,
  DefaultDescription,
  ETypeFolder,
  FormModal,
  type IApiThrowsError,
  type IColumn,
  INIT_PAGE,
  type IPaginationMetaProps,
  type ISort,
  ListLoading,
  Logger,
  MainContainer,
  ManagerRoles,
  NO_LIMIT_PAGE,
  PageContent,
  PageHeader,
  Roles,
  SearchBar,
  TableImageCell,
  TableRow,
  getRouteFrom,
  useBreadcrumbs,
  useToast,
} from '@gbs-monorepo-packages/common';

import PersonIcon from '../../assets/person.png';
import { FOLDER, FOLDERS } from '../../constants/RoutePaths';
import {
  type IDocumentDTO,
  type IPaginationDocumentDTO,
  type IRenameDocumentProps,
  type IUploadDocumentProps,
  deleteDocument,
  getListDocumentsByClient,
  renameDocument,
  uploadDocument,
} from '../../services/documentsFolder';
import {
  type ICreateFolderProps,
  type IFolderDTO,
  type IUpdateFolderProps,
  createFolder,
  deleteFolder,
  getListFolderById,
  updateFolder,
} from '../../services/folders';
import { getUserById } from '../../services/users';
import { AddFolderModal } from '../DocumentsFolders/components/AddFolder';
import { EditFolderModal } from '../DocumentsFolders/components/EditFolder';
import { FolderIcon } from '../DocumentsFolders/styles';
import { AddDocumentModal } from './components/AddDocument';
import { EditDocumentModal } from './components/EditDocument';
import { ModalPreview } from './components/ModalPreview';
import {
  ArrowDown,
  ButtonContent,
  DropdownButtonContainer,
  FileIcon,
  IconContainer,
  Subtitle,
  TableCellCustom,
  TableCustom,
} from './styles';

export const Documents = (): JSX.Element => {
  const { companyId = '', folderId = '' } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { user } = useAuth();
  const folderRoute = getRouteFrom(FOLDER);
  const foldersBaseRoute = getRouteFrom(FOLDERS);
  const { breadcrumbs, addBreadcrumb, removeBreadcrumb } = useBreadcrumbs();

  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [loadingActions, setLoadingActions] = useState(false);
  const [search, setSearch] = useState('');
  const lastSearch = useRef(search);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [folder, setFolder] = useState<IFolderDTO | null>();
  const [documents, setDocuments] = useState<IDocumentDTO[]>([]);
  const [subFolders, setSubFolders] = useState<IFolderDTO[]>([]);
  const [useDocument, setUseDocument] = useState<IDocumentDTO | null>(null);
  const [userCreatedFolder, setUserCreatedFolder] = useState<IUserDTO | null>();
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const [sortOrder, setSortOrder] = useState<ISort | null>(null);

  const [documentToDelete, setDocumentToDelete] = useState<IDocumentDTO | null>(
    null
  );
  const [openAddDocumentModal, setOpenAddDocumentModal] = useState(false);
  const [openEditDocumentModal, setOpenEditDocumentModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const isModalDocumentDeleteOpen = !!documentToDelete;

  const [folderToEdit, setFolderEdit] = useState<IFolderDTO | null>(null);
  const [folderDelete, setFolderDelete] = useState<IFolderDTO | null>(null);
  const [openAddFolderModal, setOpenAddFolderModal] = useState(false);
  const isModalSubFolderDeleteOpen = !!folderDelete;
  const companyIdRef = useRef(companyId);

  const TitlePageText = folder?.name ?? '';

  const folderTitle = useMemo(() => {
    if (!folder) return '';
    return folder?.typeFolder === ETypeFolder.PRIVATE ||
      folder?.typeFolder === ETypeFolder.RENEWAL
      ? 'Private GBS Documents'
      : 'Public Employee Documents';
  }, [folder]);

  const typeFolderDescription =
    folder?.typeFolder === ETypeFolder.PRIVATE ? 'private' : 'public';

  const DocumentHeaderColumns = useMemo(() => {
    const headersArray = [];

    headersArray.push({
      id: 'originalName',
      name: 'File Name',
      textAlign: 'start',
    });
    headersArray.push({
      id: 'name',
      name: 'Description',
      textAlign: 'start',
    });

    if (user?.roles.includes('ROLE_ADMIN') === true) {
      headersArray.push({
        id: 'highlightedDate',
        name: 'Display on Dashboard',
      });
    }

    headersArray.push({
      id: 'createdAt',
      name: 'Upload Date',
      textAlign: 'end',
    });
    headersArray.push({
      id: 'options',
      name: '',
      textAlign: 'center',
    });

    return headersArray;
  }, [user]);

  const permissionAddPrivateFolder = useMemo(() => {
    if (
      user?.roles.includes(Roles.ADMIN) === true ||
      ManagerRoles.some((role) => user?.roles.includes(role))
    ) {
      return true;
    }
    if (
      userCreatedFolder?.id === user?.id &&
      folder?.typeFolder === ETypeFolder.PRIVATE
    ) {
      return true;
    }
    return false;
  }, [folder?.typeFolder, user?.id, user?.roles, userCreatedFolder?.id]);

  const toRouteSubFolder = useCallback(
    (folderId: number | string) =>
      generatePath(folderRoute, {
        companyId,
        typeFolder: typeFolderDescription,
        folderId,
      }),
    [companyId, folderRoute, typeFolderDescription]
  );

  const handleRowClick = useCallback(
    (e: MouseEvent<HTMLDivElement>, item: IFolderDTO) => {
      const route = toRouteSubFolder(item.id);
      addBreadcrumb({
        name: item.name,
        url: route,
      });
      navigate(route, { state: { from: pathname } });
    },
    [addBreadcrumb, navigate, pathname, toRouteSubFolder]
  );

  const handleGoBack = useCallback(() => {
    const isPrivate =
      folder?.typeFolder === ETypeFolder.PRIVATE ||
      folder?.typeFolder === ETypeFolder.RENEWAL;
    if (isPrivate) {
      navigate(
        generatePath(foldersBaseRoute, { companyId, typeFolder: 'private' })
      );
      return;
    }
    navigate(
      generatePath(foldersBaseRoute, { companyId, typeFolder: 'public' })
    );
  }, [companyId, folder?.typeFolder, foldersBaseRoute, navigate]);

  useEffect(() => {
    // go back to first screen
    if (companyIdRef.current !== companyId) {
      handleGoBack();
    }

    if (!breadcrumbs) return;
    if (breadcrumbs?.length === 0) {
      handleGoBack();
    }
  }, [breadcrumbs, companyId, handleGoBack, navigate]);

  const getDocuments = useCallback(
    async (folderId: string, page: number, limit: number, filter?: string) => {
      setLoadingDocuments(true);
      try {
        const fetchedDocuments = await getListDocumentsByClient({
          folderId: folderId ?? '',
          page,
          limit,
          filter: filter ?? '',
          sort: JSON.stringify(sortOrder),
        });

        const fetchFolderInformation = await getListFolderById({
          id: Number(folderId),
        });

        const userCreateFolder = await getUserById(
          fetchFolderInformation.data.accountId
        );

        setFolder(fetchFolderInformation.data);
        setDocuments(fetchedDocuments.data.documents);
        setSubFolders(fetchedDocuments.data.subFolders);
        setPaginationMeta(fetchedDocuments.meta);
        setUserCreatedFolder(userCreateFolder);
      } catch (err) {
        Logger.debug('err: ', err);
        addToast({
          title: 'Error on load documents',
          description: DefaultDescription,
          styleType: 'error',
          dataCy: 'get-documents-error-toast',
          duration: 3000,
        });
      } finally {
        setLoadingDocuments(false);
      }
    },
    [sortOrder]
  );

  useEffect(() => {
    void getDocuments(folderId, INIT_PAGE, NO_LIMIT_PAGE, search);
  }, [folderId, sortOrder]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (search.trim() !== lastSearch.current) {
      const execSearch = () => {
        lastSearch.current = search.trim();
        setDocuments([]);
        searchDocuments(true);
      };

      if (search.trim() === '') {
        execSearch();
      } else {
        timer = setTimeout(execSearch, 2000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [search]);

  useEffect(() => {
    if (sortOrder) {
      searchDocuments(true);
    }
  }, [sortOrder]);

  const searchDocuments = useCallback(
    (searchByButton = false) => {
      if (!loadingDocuments || searchByButton) {
        setLoadingDocuments(true);
        const pageAux = searchByButton
          ? 0
          : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);
        getListDocumentsByClient({
          folderId: folderId ?? '',
          page: pageAux + 1,
          limit: NO_LIMIT_PAGE,
          filter: search ?? '',
          sort: JSON.stringify(sortOrder),
        })
          .then((response: IPaginationDocumentDTO) => {
            if (!searchByButton) {
              setDocuments(
                produce((draft) => {
                  draft.push(...response.data.documents);
                })
              );
              setSubFolders(
                produce((draft) => {
                  draft.push(...response.data.subFolders);
                })
              );
              setPaginationMeta(response.meta);
            } else {
              setDocuments(response.data.documents);
              setSubFolders(response.data.subFolders);
              setPaginationMeta(response.meta);
            }
          })
          .catch((error: IApiThrowsError) => {
            Logger.debug('error: ', error);
            addToast({
              title: 'Error on load documents',
              description: DefaultDescription,
              styleType: 'error',
              dataCy: 'get-documents-error-toast',
              duration: 3000,
            });
          })
          .finally(() => {
            setLoadingDocuments(false);
          });
      }
    },
    [
      loadingDocuments,
      paginationMeta?.page,
      folderId,
      search,
      sortOrder,
      addToast,
    ]
  );

  const addDocument = useCallback(
    async (document: IUploadDocumentProps) => {
      setLoadingActions(true);
      if (!folderId) return;

      const aux = {
        ...document,
        folderId: Number(folderId) ?? 0,
      };
      await uploadDocument(aux)
        .then((result) => {
          if (result.id) {
            addToast({
              title: 'Document uploaded successfully',
              styleType: 'success',
              dataCy: 'create-document-success-toast',
              duration: 3000,
            });
            void getDocuments(
              folderId,
              INIT_PAGE,
              NO_LIMIT_PAGE,
              lastSearch.current
            );
            setOpenAddDocumentModal(false);
          }
        })
        .catch((err) => {
          Logger.debug('err: ', err);

          let errorMsg = DefaultDescription;

          if (
            isAxiosError(err) &&
            err.response !== undefined &&
            err.response.status === 400
          ) {
            const errorData = err.response.data as {
              error: { message: string };
            };

            errorMsg = errorData.error.message;
          }

          addToast({
            title: errorMsg,
            styleType: 'error',
            dataCy: 'create-document-error-toast',
            duration: 3000,
          });
        })
        .finally(() => {
          setLoadingActions(false);
        });
    },
    [addToast, folderId, getDocuments]
  );

  const addFolder = useCallback(
    async (folder: ICreateFolderProps): Promise<boolean> => {
      let result = false;
      if (!loadingActions) {
        setLoadingActions(true);
        folder = {
          ...folder,
          folderRef: Number(folderId),
        };
        await createFolder(folder)
          .then(() => {
            addToast({
              title: 'Folder created',
              styleType: 'success',
              dataCy: 'create-folder-success-toast',
              duration: 3000,
            });
            setOpenAddFolderModal(false);
            void getDocuments(
              folderId,
              INIT_PAGE,
              NO_LIMIT_PAGE,
              lastSearch.current
            );
            result = true;
          })
          .catch((error: IApiThrowsError) => {
            addToast({
              title: 'Error on creating folder',
              description:
                error.response?.data.error &&
                error.response?.data.error.code >= 500
                  ? (DefaultDescription as string)
                  : error.response?.data.error.message,
              styleType: 'error',
              dataCy: 'create-folder-error-toast',
              duration: 3000,
            });
          })
          .finally(() => {
            setLoadingActions(false);
          });
      }

      return result;
    },
    [loadingActions, folderId, addToast, getDocuments]
  );

  const editDocument = useCallback(
    (documentAux: IRenameDocumentProps) => {
      if (!loadingActions) {
        setLoadingActions(true);

        renameDocument(documentAux)
          .then(() => {
            addToast({
              title: 'Document updated',
              styleType: 'success',
              dataCy: 'edit-document-success-toast',
              duration: 3000,
            });
            setUseDocument(null);
            setOpenEditDocumentModal(false);
            void getDocuments(
              folderId,
              INIT_PAGE,
              NO_LIMIT_PAGE,
              lastSearch.current
            );
          })
          .catch((err) => {
            Logger.debug('err: ', err);

            let errorMsg = DefaultDescription;

            if (
              isAxiosError(err) &&
              err.response !== undefined &&
              err.response.status === 400
            ) {
              const errorData = err.response.data as {
                error: { message: string };
              };

              errorMsg = errorData.error.message;
            }

            addToast({
              title: errorMsg,
              styleType: 'error',
              dataCy: 'create-document-error-toast',
              duration: 3000,
            });
          })
          .finally(() => {
            setLoadingActions(false);
          });
      }
    },
    [loadingActions, addToast, getDocuments, folderId]
  );

  const editFolder = useCallback(
    async (folder: IUpdateFolderProps): Promise<boolean> => {
      let result = false;
      if (!loadingActions) {
        setLoadingActions(true);

        await updateFolder(folder)
          .then(() => {
            addToast({
              title: 'Folder updated',
              styleType: 'success',
              dataCy: 'updated-folder-success-toast',
              duration: 3000,
            });
            void getDocuments(
              folderId,
              INIT_PAGE,
              NO_LIMIT_PAGE,
              lastSearch.current
            );
            result = true;
          })
          .catch((error: IApiThrowsError) => {
            addToast({
              title: 'Error on updated folder',
              description:
                error.response?.data.error &&
                error.response?.data.error.code >= 500
                  ? (DefaultDescription as string)
                  : error.response?.data.error.message,
              styleType: 'error',
              dataCy: 'updated-folder-error-toast',
              duration: 3000,
            });
          })
          .finally(() => {
            setLoadingActions(false);
          });
      }

      return result;
    },
    [addToast, folderId, getDocuments, loadingActions]
  );

  const deleteDocumentHandle = useCallback(() => {
    if (!documentToDelete) {
      return;
    }

    if (!loadingActions) {
      setLoadingActions(true);

      const documentId = String(documentToDelete.id);
      deleteDocument({ documentId })
        .then(() => {
          addToast({
            title: 'Document deleted',
            description: `Document was successfully deleted.`,
            styleType: 'success',
            dataCy: 'delete-document-success-toast',
            duration: 3000,
          });
          void getDocuments(
            folderId,
            INIT_PAGE,
            NO_LIMIT_PAGE,
            lastSearch.current
          );
        })
        .catch(() => {
          addToast({
            title: 'Error on deleting document',
            description: DefaultDescription as string,
            styleType: 'error',
            dataCy: 'delete-document-error-toast',
            duration: 3000,
          });
        })
        .finally(() => {
          setDocumentToDelete(null);
          setLoadingActions(false);
        });
    }
  }, [documentToDelete, loadingActions, addToast, getDocuments, folderId]);

  const removeFolder = useCallback(() => {
    if (!folderDelete) {
      return;
    }

    if (!loadingActions) {
      setLoadingActions(true);

      deleteFolder({ id: folderDelete.id })
        .then(() => {
          addToast({
            title: 'Folder deleted',
            styleType: 'success',
            dataCy: 'delete-folder-success-toast',
            duration: 3000,
          });
          void getDocuments(
            folderId,
            INIT_PAGE,
            NO_LIMIT_PAGE,
            lastSearch.current
          );
        })
        .catch(() => {
          addToast({
            title: 'Error on deleting folder',
            description: DefaultDescription as string,
            styleType: 'error',
            dataCy: 'delete-folder-error-toast',
            duration: 3000,
          });
        })
        .finally(() => {
          setFolderDelete(null);
          setLoadingActions(false);
        });
    }
  }, [addToast, folderDelete, folderId, getDocuments, loadingActions]);

  const onClipboardCopy = useCallback(
    (item: IDocumentDTO) => {
      navigator.clipboard
        ?.writeText(item.path)
        .then(() => {
          addToast({
            title: 'Link copied',
            description: `Link of document ${
              item.name ?? item.originalName ?? 'unknown'
            } was successfully copied`,
            styleType: 'success',
            dataCy: 'document-link-copied-success-toast',
          });
        })
        .catch((err) => {
          Logger.debug('err: ', err);

          addToast({
            title: "Error on copy documents's link",
            description: DefaultDescription as string,
            styleType: 'error',
            dataCy: 'document-link-copied-error-toast',
          });
        });
    },
    [addToast]
  );

  const handleDeclineDocument = useCallback(() => {
    setUseDocument(null);
    setOpenEditDocumentModal(false);
    setDocumentToDelete(null);
  }, []);

  const handleDeclineSubFolder = useCallback(() => {
    setFolderEdit(null);
    setFolderDelete(null);
    setOpenAddFolderModal(false);
  }, []);

  const formDate = (date: string) => {
    const dateAux = format(new Date(date), 'MM-dd-yyyy');
    return dateAux;
  };

  const removeBreadcrumbPage = useCallback(
    (index: number) => {
      removeBreadcrumb(index);
    },
    [removeBreadcrumb]
  );

  const hasRequestInLoading = useMemo(() => {
    return loadingDocuments || loadingActions;
  }, [loadingDocuments, loadingActions]);

  return (
    <MainContainer data-cy="document-container">
      <PageHeader
        title={TitlePageText}
        additionalActions={
          !permissionAddPrivateFolder ? null : (
            <DropdownButtonContainer data-cy="add-button-container">
              <BaseDropdown
                onOpenChange={(isOpen) => {
                  setIsDropdownOpen(isOpen);
                }}
                disabled={hasRequestInLoading}
                customTrigger={
                  <Button
                    dataCy="button-document"
                    styleType="outline"
                    noPaddingRight
                  >
                    <ButtonContent>
                      <p>New</p>
                      <IconContainer>
                        <ArrowDown />
                      </IconContainer>
                    </ButtonContent>
                  </Button>
                }
              >
                <BaseDropdownItem
                  data-cy="button-create-folder"
                  onClick={() => {
                    setOpenAddFolderModal(true);
                  }}
                >
                  Create Folder
                </BaseDropdownItem>
                <BaseDropdownItem
                  data-cy="button-upload-document"
                  onClick={() => {
                    setOpenAddDocumentModal(true);
                  }}
                >
                  Upload Document
                </BaseDropdownItem>
              </BaseDropdown>
            </DropdownButtonContainer>
          )
        }
      />

      <Subtitle>{folderTitle}</Subtitle>
      <PageContent>
        <SearchBar
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value);
          }}
          onClearInput={() => {
            setSearch('');
          }}
          search={search}
          maxLength={40}
          placeholder="Search Document"
          loading={loadingDocuments}
          id="search-document"
        />
        <BreadcrumbsComponent
          links={breadcrumbs}
          onClick={removeBreadcrumbPage}
        />
        {loadingDocuments ? (
          <ListLoading />
        ) : (
          <TableCustom
            columns={DocumentHeaderColumns}
            dataLength={documents.length + subFolders.length}
            next={searchDocuments}
            loading={loadingDocuments}
            hasMore={
              !!(
                paginationMeta?.total != null &&
                documents.length < paginationMeta?.total
              ) &&
              !!(
                paginationMeta?.total &&
                subFolders.length < paginationMeta?.total
              )
            }
            noItensMessage="No items found"
            onClickHeader={(column: IColumn) => {
              setSortOrder({
                name: column.id,
                direction: sortOrder?.direction === 'ASC' ? 'DESC' : 'ASC',
              });
            }}
            columnSorted={sortOrder}
          >
            {subFolders.map((item) => (
              <TableRow
                key={`${item.id} - ${item.name}`}
                onClick={(e) => {
                  handleRowClick(e, item);
                }}
              >
                <TableImageCell
                  text={item.name}
                  subtitle=""
                  dataCy="folder-card"
                  type="icon"
                >
                  <FolderIcon />
                </TableImageCell>
                {user?.roles.includes('ROLE_ADMIN') === true && (
                  <TableCellCustom text="" />
                )}
                <TableCellCustom text="" />
                <TableCellCustom
                  textAlign="end"
                  text={formDate(item.updatedAt ?? item.createdAt)}
                />
                <DropdownButtonContainer
                  data-cy="dropdown-container"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {permissionAddPrivateFolder ? (
                    <BaseDropdown
                      disabled={!permissionAddPrivateFolder}
                      dataCy={'dropdown-menu-button'}
                      onOpenChange={(isOpen) => {
                        setIsDropdownOpen(isOpen);
                        Logger.debug('isOpen', isOpen);
                      }}
                    >
                      <BaseDropdownItem
                        disabled={!permissionAddPrivateFolder}
                        data-cy={'edit-item'}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (permissionAddPrivateFolder) {
                            setFolderEdit(item);
                          }
                        }}
                      >
                        Edit
                      </BaseDropdownItem>

                      <BaseDropdownItem
                        disabled={!permissionAddPrivateFolder}
                        data-cy={'delete-item'}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (permissionAddPrivateFolder) {
                            setFolderDelete(item);
                          }
                        }}
                      >
                        Delete
                      </BaseDropdownItem>
                    </BaseDropdown>
                  ) : (
                    <></>
                  )}
                </DropdownButtonContainer>
              </TableRow>
            ))}

            {documents.map((item) => (
              <TableRow
                key={`${item.id} - ${
                  item.name ?? item.originalName ?? 'unknown'
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenPreviewModal(true);
                  setUseDocument(item);
                }}
              >
                <TableImageCell
                  text={
                    item.originalName
                      ? item.originalName
                      : item.name
                      ? `${item.name}`
                      : '-'
                  }
                  subtitle=""
                  dataCy="document-card"
                  type="icon"
                >
                  <FileIcon />
                </TableImageCell>
                <TableCellCustom
                  text={item.name ? `${item.name}` : '-'}
                  textAlign="start"
                />
                {user?.roles.includes('ROLE_ADMIN') === true && (
                  <TableCellCustom
                    text={item.highlightedDate !== null ? 'Yes' : 'No'}
                  />
                )}
                <TableCellCustom
                  text={formDate(item.createdAt)}
                  textAlign="end"
                />
                <DropdownButtonContainer
                  data-cy="dropdown-container"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {permissionAddPrivateFolder ? (
                    <BaseDropdown
                      dataCy={'dropdown-menu-button'}
                      onOpenChange={(isOpen) => {
                        setIsDropdownOpen(isOpen);
                        Logger.debug('isOpen', isOpen);
                      }}
                    >
                      {(folder?.typeFolder === ETypeFolder.PUBLIC ||
                        folder?.typeFolder === ETypeFolder.EDGE) && (
                        <BaseDropdownItem
                          onClick={(e) => {
                            e.stopPropagation();
                            onClipboardCopy(item);
                          }}
                          data-cy={`documents-copyLink-${item.id}`}
                        >
                          Copy Link
                        </BaseDropdownItem>
                      )}
                      <BaseDropdownItem
                        disabled={!permissionAddPrivateFolder}
                        data-cy={'edit-item'}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (permissionAddPrivateFolder) {
                            setUseDocument(item);
                            setOpenEditDocumentModal(true);
                          }
                        }}
                      >
                        Edit
                      </BaseDropdownItem>

                      <BaseDropdownItem
                        disabled={!permissionAddPrivateFolder}
                        data-cy={'delete-item'}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (permissionAddPrivateFolder) {
                            setDocumentToDelete(item);
                          }
                        }}
                      >
                        Delete
                      </BaseDropdownItem>
                      {folder?.typeFolder === ETypeFolder.PUBLIC && (
                        <BaseDropdownItem
                          onClick={() =>
                            window.open(`${item.path}`, '_blank')?.focus()
                          }
                          data-cy={`documents-download-${item.id}`}
                        >
                          Download
                        </BaseDropdownItem>
                      )}
                    </BaseDropdown>
                  ) : (
                    <></>
                  )}
                </DropdownButtonContainer>
              </TableRow>
            ))}
          </TableCustom>
        )}
      </PageContent>
      {openAddDocumentModal && (
        <AddDocumentModal
          onAccept={addDocument}
          onDecline={() => {
            setOpenAddDocumentModal(false);
          }}
          open={!isDropdownOpen && openAddDocumentModal}
          zIndex={10}
          loading={loadingActions}
        />
      )}

      {useDocument && openEditDocumentModal && (
        <EditDocumentModal
          document={useDocument}
          onAccept={editDocument}
          onDecline={handleDeclineDocument}
          open={!isDropdownOpen && useDocument !== null}
          loading={loadingActions}
          zIndex={10}
        />
      )}
      <FormModal
        dataCy="delete-document-dialog"
        acceptText="Confirm"
        declineText="Cancel"
        open={!isDropdownOpen && isModalDocumentDeleteOpen}
        loading={loadingActions}
        mainText={`Are you sure you want to delete?`}
        onAccept={deleteDocumentHandle}
        onDecline={handleDeclineDocument}
        onOpenChange={handleDeclineDocument}
        zIndex={10}
      >
        {documentToDelete && (
          <TableImageCell
            text={
              documentToDelete.name
                ? `${documentToDelete.name}.${documentToDelete.extension}`
                : documentToDelete.originalName ?? 'unknown'
            }
            dataCy="document-delete-modal-content"
            type="icon"
            src={documentToDelete.path ?? PersonIcon}
          />
        )}
      </FormModal>

      {useDocument && openPreviewModal && (
        <ModalPreview
          open={openPreviewModal}
          dataCy="form-modal"
          setWidth="80vw"
          document={useDocument}
          typeFolder={folder?.typeFolder ?? ETypeFolder.PRIVATE}
          onDecline={() => {
            setOpenPreviewModal(false);
            setUseDocument(null);
          }}
          zIndex={10}
        />
      )}

      <AddFolderModal
        typeFolder={folder?.typeFolder ?? ETypeFolder.PUBLIC}
        onAccept={addFolder}
        onDecline={() => {
          setOpenAddFolderModal(false);
        }}
        open={!isDropdownOpen && openAddFolderModal}
        loading={loadingActions}
        zIndex={10}
      />

      {folderToEdit && (
        <EditFolderModal
          folder={folderToEdit}
          onAccept={editFolder}
          onDecline={handleDeclineSubFolder}
          open={!isDropdownOpen && folderToEdit !== null}
          loading={loadingActions}
          zIndex={10}
        />
      )}

      <FormModal
        dataCy="delete-folder-dialog"
        acceptText="Confirm"
        declineText="Cancel"
        open={!isDropdownOpen && isModalSubFolderDeleteOpen}
        loading={loadingActions}
        mainText={`Are you sure you want to delete?`}
        onAccept={removeFolder}
        onDecline={handleDeclineSubFolder}
        onOpenChange={handleDeclineSubFolder}
        zIndex={10}
      >
        {folderDelete && (
          <TableImageCell
            text={`${folderDelete.name}`}
            dataCy="folder-delete-modal-content"
            type="icon"
            src={PersonIcon}
          />
        )}
      </FormModal>
    </MainContainer>
  );
};
