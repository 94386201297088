import { type AxiosError } from 'axios';

import {
  type IAuthRejectEvent,
  broadcastSignOut,
  handlerRejection,
  handlerRequest,
  notifyForbidden,
  refreshToken,
} from '@gbs-monorepo-packages/auth';
import { createApi } from '@gbs-monorepo-packages/common';

import { BASE_URL, IS_DEV } from '../constants/Env';

export interface IResponseDefaultContent {
  success: boolean;
  message: string;
}

interface IApiErrorProps {
  message: string;
  code: number;
}

export interface IPaginationMetaProps {
  count: number;
  page: number;
  total: number;
  total_pages: number;
}

export interface IErrorDefaultContent {
  error: IApiErrorProps;
  message?: string;
  title?: string;
}

export interface IResponseData<T = { message: string }> {
  data: T;
}

export interface IPaginationResponseData<T> extends IResponseData<T> {
  meta: IPaginationMetaProps;
}

export interface IResponseDocumentData<
  T = {
    document: {
      id: number;
      name: string;
      filename: string;
      extension: string;
    };
    message: string;
  }
> {
  data: T;
}

export type IThrowsError = AxiosError<IResponseDefaultContent>;
export type IApiThrowsError = AxiosError<IErrorDefaultContent>;

export const api = createApi<IAuthRejectEvent>({
  baseURL: BASE_URL,
  debug: IS_DEV,
  events: {
    handlerBroadcastSignOut: broadcastSignOut,
    handlerNotifyForbidden: notifyForbidden,
    handlerRefresh: refreshToken,
  },
  handlerRejection,
  handlerRequest,
});
